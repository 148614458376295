import React, { useState, useEffect } from 'react';
import './app.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { faInstagram } from "@fortawesome/free-brands-svg-icons"
import Swal from 'sweetalert2';

function App() {
  const [searchTerm, setSearchTerm] = useState('');
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [language] = useState(
    navigator.language.startsWith('ar') ? 'ar' : 'en'
  );

  const stringsArray = [
    "ماكدونالدز", "McDonald's",
    "كنتاكي", "KFC",
    "بابا جونز", "Papa Johns",
    "هارديز", "Hardee's",
    "برجر كينج", "Burger King",
    "ستاربكس", "Starbucks",
    "بيبسي", "Pepsi",
    "ميرندا", "Mirinda",
    "سفن أب", "7UP",
    "شاني", "Shani",
    "فانتا", "Fanta",
    "كوكاكولا", "Coca Cola",
    "سبرايت", "Sprite",
    "تانج", "Tang",
    "ليز", "LAY'S",
    "برينجلز", "Pringles",
    "ليبتون", "Lipton",
    "نيسكافيه", "NESCAFE",
    "لوريال", "LOreal",
    "دانون", "Danone",
    "كنور", "Knorr",
    "كارفور", "Carrefour",
    "مارلبورو", "Marlboro",
    "دوريتوس", "DORITOS",
    "بوما", "Puma",
    "كابي", "cappy",
    "كادبوري", "Cadbury",
    "دوف", "Dove",
    "ديتول", "Dettol",
    "كلير", "Clear",
    "نستله", "Nestle",
    "بانتين", "Pantene",
    "هيد آند شولدرز", "Head & Shoulders",
    "فانيش", "vanish",
    "بريل", "Pril",
    "أوكسي", "Oxi",
    "تروبيكانا", "Tropicana",
    "بامبرز", "Pampers",
    "نيفيا", "NIVEA",
    "شيتوس", "CHEETOS",
    "كولجيت", "Colgate",
    "فازلين", "Vaseline",
    "أولار بي", "Oral B",
    "إم آند إمز", "M&M",
    "ريكسونا", "Rexona",
    "كيت كات", "Kit kat",
    "ريد بول", "Red Bull",
    "مارلبورو", "Marlboro",
    "دانكن دوناتس", "Dunkin",
    "بريك", "break",
    "جونسون", "Johnsons",
    "شيتوس", "CHEETOS",
    "هاينز", "Heinz",
    "صن بايتس", "SunBites",
    "كندر", "Kinder"
  ];



  useEffect(() => {
    document.documentElement.lang = language;
    document.documentElement.dir = language === 'ar' ? 'rtl' : 'ltr';
  }, [language]);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    setShowSuggestions(value !== '');
  };

  const handleSearchClick = () => {
    setShowSuggestions(false);
    const found = stringsArray.some(
      (item) => item.toLowerCase() === searchTerm.toLowerCase()
    );

    if (found) {
      Swal.fire({
        title: 'Found! - تم العثور!',
        html: `<b style="color: rgb(221, 51, 51); line-height: 3;"> ${searchTerm}  </b> <br />
          This brand supports the Israeli occupation. <br />
           هذه الشركة تدعم الاحتلال الإسرائيلي`,
        icon: 'error',
        confirmButtonText: language === 'en' ? 'OK' : 'حسناً',
        confirmButtonColor: '#d33',
      });
    } else {
      Swal.fire({
        title: language === 'en' ? 'Not found!' : 'لم يتم العثور!',
        html: `<b style="color: rgb(165,220,134); line-height: 3;"> ${searchTerm}  </b> <br />
         does not exist in the list.<br /> غير موجود في القائمة.`,
        icon: 'success',
        confirmButtonText: language === 'en' ? 'OK' : 'حسناً',
        confirmButtonColor: '#3085d6',
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleSearchClick();
  };

  return (
    <div className='container'>
      <div className='row justify-content-center'>
        <div className="col-12 mb-2 text-end">
          {/* <button className="btn btn-secondary" onClick={() => setLanguage(lang => (lang === 'en' ? 'ar' : 'en'))}>
            {language === 'en' ? 'العربية' : 'English'}
          </button> */}

          {/* add instagram link  */}
          <a href="https://www.instagram.com/moqata/" target="_blank" rel="noreferrer" className='instagram-link'>
          <FontAwesomeIcon icon={faInstagram} />

            instagram
          </a>
        </div>
        <div className="content-wrapper col-12 col-md-6">
          <header className="header mb-4">
            <img src='main-image.png' className="logo" alt="logo" />
          </header>
          <div className="content">
            <form onSubmit={handleSubmit} className="search-bar input-group">
              <input
                type="text"
                className="form-control"
                placeholder={language === 'en' ? "Search" : "بحث"}
                value={searchTerm}
                onChange={handleInputChange}
                required
              />
              <button className="btn btn-outline-secondary" type="submit">
                <FontAwesomeIcon icon={faSearch} />
                {language === 'en' ? ' Search' : ' بحث'}
              </button>
            </form>
            {showSuggestions && (
              <ul className="list-group autocomplete-suggestions">
                {stringsArray.filter((item) =>
                  item.toLowerCase().includes(searchTerm.toLowerCase())
                ).map((item, index) => (
                  <li
                    key={index}
                    className="list-group-item suggestion-item"
                    onClick={() => {
                      setSearchTerm(item);
                      setShowSuggestions(false);
                    }}
                  >
                    {item}
                  </li>
                ))}
              </ul>
            )}
          </div>

          <div className='description'>
            <p>
              A platform that helps consumers make informed choices about companies that do or do not back the illegal occupation of Palestine by Israel.
            </p>
            <p style={{"direction": "rtl"}}>
              منصة تساعد المستهلكين على التعرف على الشركات التي تدعم أو لا  الاحتلال الإسرائيلي.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
